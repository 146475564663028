import useRTCEvents from "hooks/useRTCEvents";
import { IErrorInfo } from "shared/interfaces";

import { ConferenceMode, Environments, BackgroundType } from "shared/enums";
import { Session, Publisher, OpenViduError } from "openvidu-browser-v2compatibility";

import { DEFAULT_BACKGROUND, ENVIRONMENT_CONTEXT, DEFAULT_CUSTOMER } from "shared/constants";
import useRTCRoom from "hooks/useRTCSessions";
import { useEffect, useState } from "react";
import "./Conference.css";

import { useCameraDevices } from "hooks/useCamera";
import { useMicrophoneDevices } from "hooks/useMicrophone";

import Header from "./Header";
import { useParams } from "react-router-dom";

import { getTokenInformation } from "services/legacyService";
import FilterButton from "components/ControlComponent/FilterButton";
import PublisherComponent from "components/VideoRoomComponent/Publisher";
import SubscriberComponent from "components/VideoRoomComponent/Subscriber";
import VideoRoomControls from "components/VideoRoomComponent/VideoControls";
import ErrorBoundaryComponent from "components/ErrorComponent/ErrorBoundaryWrapper";
import VideoRoomTransition from "components/VideoRoomComponent/VideoTransition";

export const VideoConferenceContainer = () => {
  const [isActiveAudio, setIsActiveAudio] = useState(true);
  const [isActiveVideo, setIsActiveVideo] = useState(true);
  const [hasSessionError, setHasError] = useState(false);

  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [companyId, setCompanyCompanyId] = useState(`${DEFAULT_CUSTOMER}`);
  const [backgroundOptions, setBackgroundOptions] = useState<BackgroundType[]>([DEFAULT_BACKGROUND as BackgroundType]);

  const { defaultCamera, availableCameras } = useCameraDevices();
  const { defaultMicrophone, availableMicrophones } = useMicrophoneDevices();
  const [openviduErrorContext, setOpenviduErrorContext] = useState<OpenViduError | Error>(null!);

  const { session_id: sessionId } = useParams();

  function handleErrorCallback(error: OpenViduError | Error) {
    let errorInfo: IErrorInfo = { name: "", detail: "" };

    if (ENVIRONMENT_CONTEXT === Environments.DEVELOPMENT) {
      errorInfo.detail = `${error.message} | ${error.name}`;
    }

    setOpenviduErrorContext(error);
    setHasError(true);
  }

  function subscribeToRoomEvents(session: Session, publisher: Publisher) {
    // TODO: subscribeToCameraDevices

    subscribeToPublisherEvents(session, publisher);
    subscribeToSessionEvents(session, publisher);
  }

  useEffect(() => {
    const loadProfile = async () => {

      try {
        const profile = await getTokenInformation(sessionId!);
        const url = profile["BACKGROUND-IMAGE"];
        const id = profile["ID_CLIENTE"];
        console.log({ url, profile, id });

        if (id && url) {
          setCompanyCompanyId(id);
          setBackgroundOptions([url]);
        } 
      } catch (error) {

        console.error('Could not set profile');
        console.log(error);
      }
    };

    loadProfile();
  }, []);

  const { subscribeToSessionEvents, subscribeToPublisherEvents, subscribers, setSubscribers, sessionStatus } = useRTCEvents({
    sessionId: sessionId!,
  });

  const { mode, isReady, session, publisher, isRecovering, recoverException, applyVirtualFilter, removeVirtualFilter } = useRTCRoom({
    companyId,
    isActiveAudio,
    setIsActiveAudio,
    setSubscribers,
    defaultCamera,
    defaultMicrophone,
    availableCameras,
    handleErrorCallback,
    availableMicrophones,
    sessionId: sessionId!,
  });

  useEffect(() => {
    if (session && publisher && !isReady) {
      const options = async () => {
        console.log("Permissions", { session, publisher, isReady });
        subscribeToRoomEvents(session, publisher);
      };

      options();
    }
  }, [session, publisher]);

  useEffect(() => {
    console.log({
      hasSessionError,
      openviduErrorContext,
      isRecovering,
      recoverException,
    });
  }, [hasSessionError, openviduErrorContext, isRecovering, recoverException]);

  return (
    <div className='mainContainer'>
      <Header />
      <ErrorBoundaryComponent
        container={
          <>
            {isReady && (
              <div>
                {/* {sessionStatus !== SessionStatus.Awaiting && <LinearDeterminate items={imageUploads} />} */}
                {mode === ConferenceMode.AudioAndVideo && (
                  <div>
                    <PublisherComponent key={`pub-${Date.now()}`} publisher={publisher} />
                    {isActiveVideo && (
                      <FilterButton
                        isActiveFilter={isActiveFilter}
                        setIsActiveFilter={setIsActiveFilter}
                        backgroundOptions={backgroundOptions}
                        applyVirtualFilter={applyVirtualFilter}
                        removeVirtualFilter={removeVirtualFilter}
                      />
                    )}
                  </div>
                )}
                {subscribers.map((subscriber) => (
                  <SubscriberComponent key={`sub-${Date.now()}`} subscriber={subscriber} />
                ))}
                <VideoRoomTransition roomStatus={sessionStatus} mode={mode} />
                <VideoRoomControls
                  session={session}
                  publisher={publisher}
                  audio={{ isActiveAudio, setIsActiveAudio }}
                  filters={{ backgroundOptions, applyVirtualFilter, removeVirtualFilter }}
                  video={{ isActiveVideo, setIsActiveVideo }}
                />
              </div>
            )}
          </>
        }
        onResetState={handleErrorCallback}
      />
    </div>
  );
};
